<template>
  <div>
    <a-card>
      <h2>经销商：{{ kpiDetailOverview.traineeName || "-" }}</h2>
      <a-row>
        <a-col span="3">编码：{{ kpiDetailOverview.traineeCode || "-" }}</a-col>
        <a-col span="3">品牌：{{ kpiDetailOverview.principalName || "-" }}</a-col>
        <a-col span="3">大区：{{ kpiDetailOverview.traineeRegionName || "-" }}</a-col>
      </a-row>
    </a-card>
    <a-card style="margin: 20px 0">
      <h1>KPI详情</h1>
      <div>
        <a-form-model
            ref="searchForm"
            :model="searchForm"
            layout="inline"
        >
          <a-row>
            <a-col span="12">
              <ASpace>
                <a-form-model-item label="所在项目">
                  {{ kpiDetailOverview.projectName || "-" }}
                </a-form-model-item>
                <a-icon
                    type="info-circle"
                    theme="filled"
                    style="cursor: pointer;margin-left: -10px"
                    @click="goToPage"/>
              </ASpace>
            </a-col>
            <a-col span="12">
              <a-alert type="info" style="width: 60%">
                <template slot="description">
                  <div>统计时间：{{ projectUpdateTime.statisticsStartTime || "-" }} -
                    {{ projectUpdateTime.statisticsEndTime || "-" }}（每天13点、18点更新）
                  </div>
                  <div>更新时间：{{ projectUpdateTime.dataUpdateTime || "-" }}</div>
                </template>
              </a-alert>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="12">
              <a-form-model-item label="考核周期">
                <a-select
                    v-model="searchForm.assessmentCycleId"
                    style="width: 160px;"
                    @change="getFollower"
                >
                  <a-select-option
                      v-for="item in (assessmentCycleOptionArray || []).filter(v => v.isAllCycle != 1)"
                      :key="item.assessmentCycleId"
                      :value="item.assessmentCycleId"
                  >
                    {{ item.assessmentCycleName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="12">
              <ASpace>
                <a-form-model-item label="所属分组">
                  <a-select
                      v-model="searchForm.traineeGroupId"
                      style="width: 160px;"
                      @change="getFollower"
                  >
                    <!--<a-select-option-->
                    <!--    key=""-->
                    <!--&gt;-->
                    <!--  全部-->
                    <!--</a-select-option>-->
                    <a-select-option
                        v-for="item in groupList"
                        :key="item.traineeGroupId"
                        :value="item.traineeGroupId"
                    >
                      {{ item.traineeGroupName }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <span
                    v-if="kpiDetailOverviewDetail.followerList.length"
                    v-for="(item,index) of kpiDetailOverviewDetail.followerList"
                    :key="index"
                >
                  跟进人员：{{ item.followerName || "-" }}
                  （{{ item.followerAndRoleName || "-" }}）
                  {{ kpiDetailOverviewDetail.followerList.length > 1 ? "," : "" }}
                </span>
                <span v-if="kpiDetailOverviewDetail.followerList.length == 0">
                  跟进人员：
                  <router-link
                      :to="{ name: 'projectDetail',
                         query:{
                            tabActive:'2',
                            id:id,
                            assessmentCycleId:searchForm.assessmentCycleId,
                            isAssessmentCycleId:1
                           }
                          }"
                      target="_blank"
                  >
                  去添加
                </router-link>
                </span>
              </ASpace>
            </a-col>
          </a-row>
          <a-row style="padding-top: 40px">
            <a-col span="12">
              <ASpace>
                <a-button
                    type="primary"
                    icon="search"
                    @click="handleSearch"
                    style="margin:0 20px">
                  查询
                </a-button>
                <a-button icon="redo" @click="handleReset">重置</a-button>
              </ASpace>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-card>
    <div class="data-table">
      <a-row style="padding: 20px 0">
        <a-col :span="18">
          <p
              style="font-size: 14px;
              padding-left: 2%"
          >
            KPI完成度
            <a-tooltip>
              <template #title>
                将学员在所属分组下，指定考核周期内的所有考核指标的完成率进行算术平均计算得出(不考核指标不纳入统计)
              </template>
              <a-icon class="mr-8" type="info-circle"/>
            </a-tooltip>
            ：{{
              Tool.roundToNumber(kpiDetailOverviewDetail.traineeGroupKpiRate * 100) || "0"
            }}%
            （{{ kpiDetailOverviewDetail.traineeGroupType == 1 ? "短视频" : "直播" }}组学员KPI完成度均值
            {{ Tool.roundToNumber(kpiDetailOverviewDetail.traineeKpiRate * 100) || "0" }}%）
          </p>
        </a-col>
        <a-col :span="6">
          <a-button @click="handleBtn(0)">学员KPI</a-button>
        </a-col>
      </a-row>
      <div>
        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="list"
            :row-key="(record, index) => index"
            :pagination="false"
            :scroll="{ x: 1000}"
            :locale="{emptyText: getLocaleEmpty}"
            @change="handleTableChange"
        >
          <div slot="KPIcustomTitle">
            指标缺口
            <a-tooltip>
              <template #title>
                指标缺口=目标值-完成值。结果为正数表示离达成目标还差多少；结果为负数表示超出目标多少
              </template>
              <a-icon class="mr-8" type="info-circle"/>
            </a-tooltip>
          </div>
          <div slot="finishRateTitle">
            完成率
            <a-tooltip>
              <template #title>
                完成率=完成值/目标值
              </template>
              <a-icon class="mr-8" type="info-circle"/>
            </a-tooltip>
          </div>
          <div slot="similarValueTitle">
            同类型学员完成均值
            <a-tooltip>
              <template #title>
                相同类型的分组中，所有学员该指标的完成值的均值
              </template>
              <a-icon class="mr-8" type="info-circle"/>
            </a-tooltip>
          </div>
          <div slot="normMinusValue" slot-scope="text, record">
            <div>{{ record.normMinusValue || "-" }}</div>
          </div>
          <div slot="normName" slot-scope="text, record">
            <div class="detail-item">
              {{ record.normName || "-" }}
            </div>
          </div>
          <div slot="normCompletionRate" slot-scope="text, record">
            <div>{{ Tool.roundToNumber(record.normCompletionRate * 100) + "%" }}</div>
          </div>

          <div slot="groupInfo" slot-scope="text, record">
            <div class="detail-item">
              <span class="detail-item__label">分组名称：</span>
              <span class="detail-item__value">{{ record.groupName || "-" }}</span>
            </div>
            <div class="detail-item">
              <span class="detail-item__label">分组类型：</span>
              <span class="detail-item__value">
              {{ record.groupType == 1 ? "短视频" : record.groupType == 2 ? "直播" : "-" }}
            </span>
            </div>
          </div>
          <div slot="students" slot-scope="text, record">
            <a-tag color="#87d068" v-if="record.comparisonLevel == 1">高于平均</a-tag>
            <a-tag color="#f50" v-else-if="record.comparisonLevel == 2">持平</a-tag>
            <a-tag color="red" v-else-if="record.comparisonLevel == 3">低于平均</a-tag>
            <span v-else>-</span>
          </div>
          <div slot="KPIComplete" slot-scope="text, record">
            <div class="detail-item">
              <span class="detail-item__progress">直播：</span>
              <a-progress :percent="90"/>
            </div>
            <div class="detail-item">
              <span class="detail-item__progress">短视频：</span>
              <a-progress :percent="70"/>
            </div>
          </div>
          <div slot="action" slot-scope="text, record">
            <router-link
                :to="{ name: 'KPIPageDetail',query:{id:id}}"
                target="_blank"
                v-if="radioValue == 0"
            >
              查看详情
            </router-link>
            <router-link
                :to="{ name: 'projectDetail', query: { id: record.id } }"
                target="_blank"
                v-else
            >
              查看学员KPI结果
            </router-link>
          </div>
        </a-table>
        <base-pagination
            :current-page="pagination.current"
            :page-size="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _API from "@/api/projectManagement";
import { cloneDeep, isEmpty, isEqual } from "lodash-es";
import moment from "moment/moment";
import { createNamespacedHelpers } from "vuex";
import vEmpty from "./components/v-empty";
import { Tool } from "@/utils";

const {mapActions} = createNamespacedHelpers("temporary_parameters");
const {mapGetters} = createNamespacedHelpers("temporary_parameters");

export default {
  name: "KPIPageDetail",
  components: {vEmpty},
  data() {
    return {
      Tool,
      id: this.$route.query.id || "",
      traineeId: this.$route.query.traineeId || "",
      traineeKpiId: this.$route.query.traineeKpiId || "",
      projectUpdateTime: this.$route.query.projectUpdateTime ? JSON.parse(this.$route.query.projectUpdateTime) : {},
      searchForm: {
        traineeGroupId: "",
        assessmentCycleId: "",
      },
      assessmentCycleOptionArray: [],
      groupList: [],
      columns: [
        {
          title: "指标名称",
          dataIndex: "normName",
          width: 200,
          scopedSlots: {customRender: "normName"},
        },
        {
          title: "目标值",
          width: 200,
          dataIndex: "normTarget",
          customRender: function(value) {
            return value || "-";
          },
        },
        {
          title: "完成值",
          width: 200,
          dataIndex: "normCompletionValue",
          customRender: function(value) {
            return value || "-";
          },
        },
        {
          // dataIndex: "kpiRate",
          width: 160,
          scopedSlots: {customRender: "normMinusValue"},
          slots: {title: "KPIcustomTitle"},
        },
        {
          dataIndex: "normCompletionRate",
          width: 160,
          sorter: true,
          scopedSlots: {customRender: "normCompletionRate"},
          slots: {title: "finishRateTitle"},
        },
        {
          width: 180,
          dataIndex: "similarTraineeCompletionValueAvg",
          slots: {title: "similarValueTitle"},
          customRender: function(value) {
            return value || "-";
          },
        },
        {
          title: "对比同类型学员",
          width: 200,
          scopedSlots: {customRender: "students"},
        },
      ],
      list: [],
      loading: false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      kpiDetailOverview: {},
      kpiDetailOverviewDetail: {
        followerList: []
      },
      follower_list: [],
      project_data: {}
    };
  },
  mounted() {
    this.getProjectDetail();
  },
  methods: {
    ...mapActions(["setTemporaryParameters"]),
    getProjectDetail() {
      _API.getProjectDetail(this.id).then((res) => {
        if(res.code == 200) {
          this.project_data = res.data || {};
          this.setTemporaryParameters(res.data);
          if(this.project_data.kpiBusinessId) {
            this.getKpiDetailOverview();
          }
        } else {
          this.$message.error(res.message || "获取详情失败");
        }
      });
    },
    handleTableChange(pagination, filters, sorter) {
      const {columnKey, order} = sorter;

      if(isEqual(columnKey, "normCompletionRate")) {
        const obj = {ascend: "descend", descend: "", def: "ascend"};
        const sortOrder = obj[order || "def"];

        this.columns = this.columns.map(v => ({...v, ...(isEqual(v.dataIndex, columnKey) ? {sortOrder} : {})}));
        this.order = {ascend: "ASC", descend: "DESC"}[sortOrder] || "";
      }

      this.getKpiDetailList();
    },
    getKpiDetailOverview() {
      _API.getKpiDetailOverview({
        traineeKpiId: this.traineeKpiId,
        businessId: this.project_data.kpiBusinessId,
        traineeId: this.traineeId,
      }).then(res => {
        if(res.code == 200) {
          this.groupList = res.data.groupSelectOptionVOList || [];
          this.assessmentCycleOptionArray = res.data.cycleSelectOptionVOList || [];
          this.kpiDetailOverview = res.data || {};
          this.$set(this.searchForm, "traineeGroupId", res.data.traineeGroupId || "");
          this.$set(this.searchForm, "assessmentCycleId", res.data.assessmentCycleId || "");

          if(this.searchForm.traineeGroupId && this.searchForm.assessmentCycleId) {
            this.getTraineeKpiRate();
          }
          if(this.kpiDetailOverview.traineeId) {
            this.getKpiDetailList();
          } else {
            this.$message.error("学员id不能为空！");
          }
        } else {
          this.groupList = [];
          this.assessmentCycleOptionArray = [];
          this.kpiDetailOverview = {};
          this.$message.error(res.message || "获取数据失败");
        }
      });
    },
    getTraineeKpiRate() {
      let params = {
        assessmentCycleId: this.searchForm.assessmentCycleId,
        traineeGroupId: this.searchForm.traineeGroupId,
        businessId: this.project_data.kpiBusinessId,
        traineeId: this.kpiDetailOverview.traineeId,
        traineeKpiId: this.traineeKpiId
      };
      _API.getTraineeKpiRate(params).then(res => {
        if(res.code == 200) {
          this.kpiDetailOverviewDetail = res.data || {};
        } else {
          this.kpiDetailOverviewDetail = {};
          this.$message.error(res.message || "获取数据失败");
        }
      });
    },
    getKpiDetailList() {
      this.loading = true;
      let params = this.searchForm;
      params.page = this.pagination.current;
      params.size = this.pagination.pageSize;
      params.sort = this.order;
      params.traineeId = this.kpiDetailOverview.traineeId;
      params.traineeKpiId = this.traineeKpiId;
      _API.getKpiDetailList(params).then(res => {
        if(res.code == 200) {
          this.list = res.data.list || [];
          this.pagination.total = res.data.total || 0;
          this.columns.push(
              {
                title: this.list.length ? this.list[0].dayDataDate + "日数据" : "",
                width: 200,
                dataIndex: "dayData",
                customRender: function(value) {
                  return value || "-";
                },
              },
          );
        } else {
          this.list = [];
          this.pagination.total = 0;
          this.$message.error(res.message || "获取数据失败");
        }
        this.loading = false;
      });
    },
    handleSearch() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getKpiDetailList();
    },
    handleReset() {
      // this.searchForm = {
      //   traineeGroupId: "",
      //   assessmentCycleId: "",
      // };
      this.$set(this.searchForm, "traineeGroupId", this.kpiDetailOverview.groupTraineeId || "");
      this.$set(this.searchForm, "assessmentCycleId", this.kpiDetailOverview.assessmentCycleId || "");
      this.pagination.pageSize = 10;
      this.pagination.current = 1;
      this.getKpiDetailList();
    },
    getFollowerOptions() {
      _API.getFollowerOptions({businessId: this.getTemporaryParameters.kpiBusinessId}).then(res => {
        if(res.code == 200) {
          this.assessmentCycleOptionArray = res.data.assessmentCycleOptionArray;
        } else {
          this.assessmentCycleOptionArray = [];
          this.$message.error(res.message || "获取失败");
        }
      });
    },
    goToPage() {
      const projectDetail_routePage = this.$router.resolve({
        name: "projectDetail",
        query: {tabActive: 1, id: this.id,}
      });
      window.open(projectDetail_routePage.href, "_blank");
    },
    handleBtn(type) {
      switch(type) {
        case 0:
          // console.log("学员KPI");
          let route = this.$router.resolve({
            path: "/KPIAssessmentSetting",
            query: {
              projectId: this.id,
              kpiBusinessId: this.project_data.kpiBusinessId,
              activeKey: "3",
              traineeName: this.kpiDetailOverview.traineeName,
              groupType: this.kpiDetailOverview.groupType,
            },
          });
          window.open(route.href, "_blank");
          break;
      }
    },
    handlePaginationChange(page, size) {
      // console.log(page, size);
      this.pagination.current = page;
      this.pagination.pageSize = size;
      this.getKpiDetailList();
    },
    handleGoEmpty(type) {
      switch(type) {
        case "1":
          let route = this.$router.resolve({
            path: "/KPIAssessmentSetting",
            query: {
              projectId: this.id,
              kpiBusinessId: this.project_data.kpiBusinessId,
              activeKey: "2",
              groupType: Number(this.searchForm.groupTraineeId),
            },
          });
          window.open(route.href, "_blank");
          break;
      }
    },
    getFollower() {
      this.getTraineeKpiRate();
      // const Item = this.groupList.filter(v => v.traineeGroupId == this.searchForm.traineeGroupId);
      // this.follower_list = Item[0].follower || [];
    }
  },
  computed: {
    ...mapGetters(["getTemporaryParameters"]),
    getLocaleEmpty() {
      if(isEmpty(this.list)) return <vEmpty text="未设置考核指标" onTo={ this.handleGoEmpty.bind(this, "1") } isLink/>;
      const {projectStatus} = this.getTemporaryParameters;
      if(isEqual(projectStatus, 2)) return <vEmpty text="考核周期未开始"/>;
      const isUpdate = ""; //后端返回
      if(isUpdate) return;
      <vEmpty text="开始考核后，将从次日起开始生产数据，持续更新到全周期结束T+6日。隔天更新2次，分别在13点、18点更新。"/>;

      return <vEmpty text="暂无数据"/>;
    },
  },
};
</script>

<style scoped lang="less">
.detail-item {
  display: flex;
  font-weight: bold;

  &__value {
    flex: 1;
    font-weight: 400;
  }

  &__progress {
    width: 80px;
    flex-wrap: nowrap;
  }
}
</style>

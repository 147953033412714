<template>
  <div class="empty_box">
    <div class="detail-item__value" @click="handleLink" :class="{isLink}">
      {{ text }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: "未添加学员",
    },
    isLink: Boolean,
  },
  methods: {
    handleLink() {
      this.$emit("to");
    },
  },
};
</script>
<style lang="scss" scoped>
.empty_box {
  width: 150px;
  margin: 26px auto;

  .detail-item__value {
    color: #ccc;

    &.isLink {
      color: #1890ff;
      cursor: pointer;
    }
  }
}
</style>
